import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { createStructuredSelector } from 'reselect';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dashboard from '../../../../component/Dashboard';
import { createUnderwriter, doCreateUnderwriterRes } from '../../../../actions/createUnderwriterActions';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import validate from './formValidation';
class CreateUnderwriterForm extends React.Component { 
  constructor() {
    super();
    this.state = {
      name: '',
      commission_percentage: '',
      mpid: '',
      clearing_broker: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      phone: '',
      zipcode: '',
      msda: false,
      isSubmited: false,
      tabIndex: 0,
      dtc: '',
    }
  }
  componentDidMount() {
    if (this.props.location.state && this.props.location.state.tabIndex) {
      this.setState({
        tabIndex: this.props.location.state.tabIndex
      });
    }
  }
  static getDerivedStateFromProps(props, state) {
		if (props.createUnderwriterRes) {
      if (props.createUnderwriterRes.data && props.createUnderwriterRes.data.createUnderwriter) {
        if (props.createUnderwriterRes.data.createUnderwriter.message === "Success") {
          props.history.push({
            pathname: `/system-setting`,
            state: {
                tabIndex: state.tabIndex,
            }
          });
        }
      }
    }
		return null
  }
  
  goBack = () => {
    // this.props.history.goBack();
    this.props.history.push({
      pathname: `/system-setting`,
      state: {
          tabIndex: this.state.tabIndex,
      }
    });
  }
  saveMessage = () => {
    this.setState({
      isSubmited: true,
    }, () => { });
    validate(this.state);
    const errors = validate(this.state);
    
    if (Object.keys(errors).length === 0) {
      let payload = {
        name: this.state.name,
        commission_percentage: this.state.commission_percentage,
        intact_mpid: this.state.mpid,
        mpid: this.state.mpid,
        clearing_broker: this.state.clearing_broker,
        address_attributes: {
          address: this.state.address,
          address2: this.state.address2,
          city: this.state.city,
          state: this.state.state,
          country: this.state.country,
          phone: this.state.phone,
          zipcode: this.state.zipcode,
        },
        msda: this.state.msda,
        dtc: this.state.dtc,
        type: 'underwriter'
      }
      this.props.handleFormSubmit(payload);
    } 
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  
  handleChangeCheckbox = (e) => {
    this.setState({
      [e.target.name]: e.target.checked
    })
  }
  render() {
    const errors = validate(this.state);
    return (
      <Dashboard title="ClickIPO - Create New Underwriter">
        <div className="admin_offering_content">
          <div className="">
            <div  className="Headingname">
              <ArrowBackIosIcon onClick={() => this.goBack()} style={{ cursor: 'pointer' }} /> Create New Underwriter
            </div>
            <form autoComplete="off"  style={{ marginTop: 30 }}>
              <div className="row">
                <div className="col-md-6 col-sm-12"  style={{ marginTop: 20 }}>
                  <TextField name="name" fullWidth label="Name of underwriter" onChange={this.handleChange} />
                  {errors && this.state.isSubmited && <span className="errorvalidation">{errors.name}</span>}
                </div>
                <div className="col-md-6 col-sm-12"  style={{ marginTop: 20 }}>
                  <TextField name="dtc" fullWidth label="DTC" onChange={this.handleChange} />
                  {errors && this.state.isSubmited && <span className="errorvalidation">{errors.dtc}</span>}
                </div>
              </div>
              <div className="row">
              <div className="col-md-6 col-sm-12"  style={{ marginTop: 20 }}>
                  <TextField name="address" fullWidth label="Address" onChange={this.handleChange} />
                  {errors && this.state.isSubmited && <span className="errorvalidation">{errors.address}</span>}
                </div>
                <div className="col-md-6 col-sm-12"  style={{ marginTop: 20 }}>
                  <TextField name="address2" fullWidth label="Address2" onChange={this.handleChange} />
                  {errors && this.state.isSubmited && <span className="errorvalidation">{errors.address2}</span>}
                </div>
                <div className="col-md-6 col-sm-12"  style={{ marginTop: 20 }}>
                  <TextField name="city" fullWidth label="City" onChange={this.handleChange} />
                  {errors && this.state.isSubmited && <span className="errorvalidation">{errors.city}</span>}
                </div>
                <div className="col-md-6 col-sm-12"  style={{ marginTop: 20 }}>
                  <TextField name="state" fullWidth label="State" onChange={this.handleChange} />
                  {errors && this.state.isSubmited && <span className="errorvalidation">{errors.state}</span>}
                </div>
                <div className="col-md-6 col-sm-12"  style={{ marginTop: 20 }}>
                  <TextField name="country" fullWidth label="Country" onChange={this.handleChange} />
                  {errors && this.state.isSubmited && <span className="errorvalidation">{errors.country}</span>}
                </div>
                <div className="col-md-6 col-sm-12"  style={{ marginTop: 20 }}>
                  <TextField name="zipcode" fullWidth label="Postal Code" type="number" onChange={this.handleChange} />
                  {errors && this.state.isSubmited && <span className="errorvalidation">{errors.zipcode}</span>}
                </div>
                <div className="col-md-6 col-sm-12"  style={{ marginTop: 20 }}>
                  <TextField name="phone" fullWidth label="Phone" type="number" onChange={this.handleChange} />
                  {errors && this.state.isSubmited && <span className="errorvalidation">{errors.phone}</span>}
                </div>
                <div className="col-md-6 col-sm-12"  style={{ marginTop: 20 }}>
                  <TextField name="commission_percentage" fullWidth type="number" label="Commission Percentage(%)" onChange={this.handleChange} />
                  {errors && this.state.isSubmited && <span className="errorvalidation">{errors.commission_percentage}</span>}
                </div>
                <div className="col-md-6 col-sm-12"  style={{ marginTop: 20 }}>
                  <TextField name="mpid" fullWidth label="MPID" onChange={this.handleChange} />
                  {errors && this.state.isSubmited && <span className="errorvalidation">{errors.mpid}</span>}
                </div>
                <div className="col-md-6 col-sm-12"  style={{ marginTop: 20 }}>
                  <TextField name="clearing_broker" fullWidth label="Clearing Broker" onChange={this.handleChange} />
                  {errors && this.state.isSubmited && <span className="errorvalidation">{errors.clearing_broker}</span>}
                </div>
                <div className="col-md-6 col-sm-12" style={{ marginTop: 40 }}>
                  <FormControlLabel
                    control={<Checkbox checked={this.state.msda} onChange={this.handleChangeCheckbox} name="msda" />}
                    label="MSDA"
                  />
                </div>
              </div>
            </form>
            <div className="row">
              <div className="col-md-12" style={{ textAlign: 'center' }}>
                <Button variant="contained" color="primary" onClick={this.saveMessage} style={{ marginTop: '30px' }} >Submit</Button>
              </div>
            </div>
          </div>
        </div>
      </Dashboard>
    );
  }
}

CreateUnderwriterForm.propTypes = {
  createUnderwriterRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
  createUnderwriterRes: doCreateUnderwriterRes,
});

function mapDispatchToProps(dispatch) {
  return {
    handleFormSubmit: (data) => dispatch(createUnderwriter(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUnderwriterForm);
